import { redirect } from '@remix-run/node'
import type { LoaderFunction } from '@remix-run/node'
import type { ClientLoaderFunctionArgs } from '@remix-run/react'
import { Outlet, useLocation } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { motion } from 'framer-motion'

import { motionPageSlideInOutProps } from '~/components/PageAnimations'
import { deprecatedCookieNames } from '~/res/constants/cookies.server'
import { processAuthTokens } from '~/utils/auth.util/tokens.service.server'
import WorkboxWindow from '~/WorkboxWindow'

export { ignoreDialogRevalidation as shouldRevalidate } from '~/revalidationRules'

export const loader: LoaderFunction = async ({ request }) => {
  // Limpar cookies (equivalente a withClearCookies)
  const headers = new Headers()
  deprecatedCookieNames.forEach((cookieName) =>
    headers.append('Set-Cookie', `${cookieName}=; Max-Age=0`),
  )

  // Processa tokens de autenticação
  const { headers: authHeaders, authorized } = await processAuthTokens(request)

  // Combina os headers
  authHeaders.forEach((value, key) => {
    headers.append(key, value)
  })

  // Redirecionamento (equivalente a withAuthorizerRedirect)
  if (authorized) {
    return redirect('/', { status: 302, headers })
  }

  return { headers }
}

export const clientLoader = async ({
  serverLoader,
}: ClientLoaderFunctionArgs) => {
  const serverData = await serverLoader()

  try {
    WorkboxWindow.getInstance().clearCache()
  } catch (err) {
    Sentry.captureException(err, {
      extra: {
        error: 'Failed to clear cache',
      },
    })
  }

  return serverData
}

clientLoader.hydrate = true

export default () => {
  const location = useLocation()

  return (
    <motion.div
      key={location.pathname}
      className="flex grow flex-col"
      {...motionPageSlideInOutProps}
    >
      <Outlet />
    </motion.div>
  )
}
